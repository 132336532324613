import * as constants from './constants'
import reducers from './reducers'

const defaultState = {
  error: undefined,
  status: undefined,
  results: [],
  count: 0
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.RESET_STORE:
      return defaultState
    case constants.GET_USER_FOLLOWUPS:
      return reducers.getUserFollowUps(state, action)
    case constants.GET_USER_FOLLOWUP_COUNT:
      return reducers.getUserFollowUpCount(state, action)
    default:
      return state
  }
}
