import { actions as reportsActions } from '../reports'
import { constants as authConstants } from '../auth'
import { PENDING, SUCCESS, FAIL } from '../../reduxPromise'

class ReportsMiddleware {
  resetInitialValuesOnLogout = ({ dispatch, getState }) => next => (action) => {
    next(action)

    if (action.status === SUCCESS && action.type === authConstants.LOGOUT) {
      dispatch(reportsActions.resetStore())
    }
  }
}

export default new ReportsMiddleware()
