import { actions as authActions, constants as authConstants } from '../auth'
import { SUCCESS, FAIL } from '../../reduxPromise'
import _ from 'lodash'
import { toast } from 'react-toastify';
import ToastText from '../../../components/ToastText'
class AuthMiddleware {
    // Restore any session credentials
    restoreCredential = ({ dispatch, getState }) => next => (action) => {
        next(action)
        if (_.get(action, 'payload.auth.status') === SUCCESS && action.type === 'persist/REHYDRATE') {
            dispatch(authActions.restore())
        }
      }

    editWatchlistToast = ({ dispatch, getState }) => next => (action) => {
        next(action)
        if (action.type === authConstants.EDIT_WATCHLIST_ADD) {
            if (action.status === SUCCESS) {
                toast.success(<ToastText translationKey="WATCHLIST_PRODUCT_ADDED" />);
            } 
            else if (action.status === FAIL) {
                toast.error(<ToastText translationKey="WATCHLIST_PRODUCT_FAILED" />);
            }
        }
        if (action.type === authConstants.EDIT_WATCHLIST_REMOVE) {
            if (action.status === SUCCESS) {
                toast.success(<ToastText translationKey="WATCHLIST_PRODUCT_REMOVED" />);
            } 
            else if (action.status === FAIL) {
                toast.error(<ToastText translationKey="WATCHLIST_PRODUCT_FAILED" />);
            }
        }
        
      }
}

export default new AuthMiddleware()
