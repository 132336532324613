import useTranslate from '../hooks/useTranslate'

const ToastText = ({ translationKey }) => {
  const translate = useTranslate()
  return (
    <div>{translate(translationKey)}</div>
  )
  
}

export default ToastText
