import * as constants from './constants'
import * as selectors from './selectors'
import sdk, { baseUrl } from 'services/vigilanceHubService'

class ReportsActions {
  getUserFollowUps = () => ({
    type: constants.GET_USER_FOLLOWUPS,
    promise: (dispatch, getState) => {
      return sdk.followUp.fetchFollowUpsForCurrentUser({ type: 'SENT' })
    }
  })
  getUserFollowUpCount = () => ({
    type: constants.GET_USER_FOLLOWUP_COUNT,
    promise: (dispatch, getState) => {
      return sdk.followUp.fetchFollowUpCountForCurrentUser({ type: 'SENT' })
    }
  })
  resetStore = () => ({
    type: constants.RESET_STORE
  })

}

export default new ReportsActions()
