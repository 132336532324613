import _ from 'lodash'
import { PENDING, SUCCESS, FAIL } from '../../reduxPromise'

class AuthReducers {
  login = (state, action) => {
    switch (action.status) {
      case PENDING:
        return {
          ...state,
          status: PENDING,
          action: action.type,
          keepMeLoggedIn: false
        }
      case SUCCESS:
        return {
          ...state,
          status: SUCCESS,
          action: undefined,
          user: { ...state.user, ...action.result },
          error: {},
          restoreStatus: SUCCESS,
          isLoggedOut: false
        }
      case FAIL:
        return {
          ...state,
          status: FAIL,
          error: action.error,
          keepMeLoggedIn: false
        }
      default:
        return state
    }
  }

  restore = (state, action) => {
    return {
      ...state,
      restoreStatus: action.status
    }
  }

  logout = (state, action) => {
    switch (action.status) {
      case PENDING:
        return {
          ...state,
          status: PENDING,
          action: action.type,
          isLoggedOut: false
        }
      case SUCCESS:
        return {
          ...state,
          status: SUCCESS,
          action: undefined,
          user: undefined,
          keepMeLoggedIn: false,
          restoreStatus: undefined,
          isLoggedOut: true
        }
      case FAIL:
        return {
          ...state,
          status: SUCCESS,
          action: undefined,
          user: undefined,
          keepMeLoggedIn: false,
          isLoggedOut: false
        }
      default:
        return state
    }
  }

  register = (state, action) => {
    switch (action.status) {
      case PENDING:
        return {
          ...state,
          status: PENDING,
          action: action.type
        }
      case SUCCESS:
        return {
          ...state,
          status: SUCCESS,
          action: undefined,
          user: { ...state.user, ...action.result },
          error: {}
        }
      case FAIL:
        return {
          ...state,
          status: FAIL,
          error: action.error
        }
      default:
        return state
    }
  }
  
  updateUser = (state, action) => {
    if(action.status !== SUCCESS){
      return { ...state }
    }
    const user = _.get(action, 'result', {})
    return {
      ...state,
      user: {
        ...state.user,
        ...user
      }
    }
  }

  editWatchList = (state, action) => {
    switch (action.status) {
      case PENDING:
        return {
          ...state,
          status: PENDING,
          action: action.type
        }
      case SUCCESS:
        return {
          ...state,
          status: SUCCESS,
          action: undefined,
          user: { ...state.user, ...action.result },
          error: {}
        }
      case FAIL:
        return {
          ...state,
          status: FAIL,
          error: action.error
        }
      default:
        return state
    }
  }
  
  setKeepMeLoggedIn = (state, action) => {
    return {
      ...state,
      keepMeLoggedIn: action.payload
    }
  }
}
export default new AuthReducers()
