import { LOGIN, LOGOUT, UPDATE_AUTH_USER, REGISTER, SET_KEEP_ME_LOGGED_IN, EDIT_WATCHLIST_ADD, EDIT_WATCHLIST_REMOVE, RESTORE } from './constants'
import reducers from './reducers'

const defaultState = {
  user: undefined,
  status: undefined,
  error: undefined,
  action: undefined,
  keepMeLoggedIn: false,
  restoreStatus: undefined
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case LOGIN:
      return reducers.login(state, action)
    case REGISTER:
      return reducers.register(state, action)
    case LOGOUT:
      return reducers.logout(state, action)
    case UPDATE_AUTH_USER:
      return reducers.updateUser(state, action)
    case SET_KEEP_ME_LOGGED_IN:
      return reducers.setKeepMeLoggedIn(state, action)
    case EDIT_WATCHLIST_ADD:
    case EDIT_WATCHLIST_REMOVE:
      return reducers.editWatchList(state, action)
    case RESTORE:
      return reducers.restore(state, action)
    default:
      return state
  }
}
