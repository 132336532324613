import _ from 'lodash'
import { createSelector } from 'reselect'
import { PENDING } from '../../reduxPromise'

export const getIsLoading = state => _.get(state, 'followUps.status') === PENDING
export const getFollowUps = state => _.get(state, 'followUps.results') || []
export const getFollowUpsCount = state => _.get(state, 'followUps.count', 0)
export const getError = state => _.get(state, 'followUps.error')
export const getStatus = state => _.get(state, 'followUps.status')
export const getReportFilters = state => _.get(state.reports, 'filters', {})

export const getFollowUpsSelector = createSelector(
  getFollowUps,
  getReportFilters,
  (followUps, filters) => {
    const extReportId = _.get(filters, 'extReportId')

    if (extReportId !== 'all') {
      return _.filter(followUps, { report: { extReportId } })
    }

    const orderedFollowUps = _.orderBy(followUps, 'scheduledDate', 'desc')
    
    return orderedFollowUps
  }
)
