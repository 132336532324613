import moment from 'moment'
import _ from 'lodash'
import { LOGIN, RESTORE, RESET_PASSWORD, CHANGE_PASSWORD, LOGOUT, UPDATE_AUTH_USER, REGISTER, CHANGE_CURRENT_USER_PASSWORD, SET_KEEP_ME_LOGGED_IN, EDIT_WATCHLIST_ADD, EDIT_WATCHLIST_REMOVE } from './constants'
import sdk, { parseError } from 'services/vigilanceHubService'
import { actions as reportsActions } from '../reports'
import { actions as followUpActions } from '../followUps'
import settings from 'config/settings'

class AuthActions {
  login = ({ email, username, password, keepMeLoggedIn = false }) => ({
    type: LOGIN,
    promise: (dispatch, getState) => {
      const { config } = getState()
      const platformId = _.get(config, 'websiteConfig.vigilanceHubPlatformId')
      const organisationId = _.get(config, 'websiteConfig.vigilanceHubOrganisationId')

      return sdk
        .auth
        .login({ email, username, password, platformId, organisationId }).then(user => {
          user.tokenRefreshDate = moment().toISOString()
          // keepMeLoggedIn is an array ['on'] when checked and an empty string when unchecked, if unchecked set to a unix time timeout
          dispatch(this.keepMeLoggedIn(_.get(keepMeLoggedIn, '0') === 'on' ? true : moment().add(1, 'hours').toISOString()))
          return user
        })
    }
  })

  restore = () => ({
    type: RESTORE,
    promise: async (dispatch, getState) => {
      const { auth } = getState()
      if (auth && auth.user) {
        await sdk.auth.setCredentials(auth.user)
        const tokenExpired = moment(auth.user.tokenRefreshDate).add(settings.tokenRefreshTimeMs, 'ms').isBefore(moment())
        
        if (tokenExpired) {
          if (auth.user){
            try {
              auth.user.tokenRefreshDate = moment().toISOString()
              await sdk.auth.setCredentials(auth.user)
              await sdk.auth.getFreshToken()
              return auth
            } catch (e) {
              dispatch(this.logout({ skipApi: true }))
            }
          } else {
            dispatch(this.logout({ skipApi: false }))
          }
        }
      }
      return auth 
    }
  })

  register = ({
    title,
    firstName,
    lastName,
    email,
    houseNumberOrName,
    company,
    address,
    addressLineTwo,
    city,
    county,
    postalCode,
    password,
    telephoneCountryCode,
    telephone,
    telephoneExtension,
    smsCommunicationsPermitted,
    emailCommunicationsPermitted,
    professionId
  }) => ({
    type: REGISTER,
    promise: (dispatch, getState) => {
      const { config } = getState()
      const organisationId = _.get(config, 'websiteConfig.vigilanceHubOrganisationId')
      const smsPermitted = _.get(smsCommunicationsPermitted, '0') === 'on'
      const emailPermitted = _.get(emailCommunicationsPermitted, '0') === 'on'

      return sdk
        .users
        .createUser({
          title,
          firstName,
          lastName,
          email,
          houseNumberOrName,
          company,
          address,
          addressLineTwo,
          city,
          county,
          postalCode,
          password,
          telephoneCountryCode,
          telephone,
          telephoneExtension,
          smsCommunicationsPermitted: smsPermitted,
          emailCommunicationsPermitted: emailPermitted,
          professionId,
          isPublic: true,
          organisationIds: [organisationId]
        })
        .then(() => {
          return dispatch(this.login({ email, password }))
        })
    }
  })

  resetPassword = ({ email, username }) => ({
    type: RESET_PASSWORD,
    promise: (dispatch, getState) => {
    const { config } = getState()
    const platformId = _.get(config, 'websiteConfig.vigilanceHubPlatformId')
    const themeId = _.get(config, 'websiteConfig.vigilanceHubThemeId')
    return sdk
      .auth
      .resetPassword({ email, username, themeId, platformId })
    }
  })

  changeCurrentUserPassword = ({ password, currentPassword }) => ({
    type: CHANGE_CURRENT_USER_PASSWORD,
    promise: (dispatch, getState) => {
    return sdk
      .auth
      .changePasswordByUserId({ id: '@me', password, currentPassword })
      .then(() => {
        dispatch(this.logout({ skipApi: true }))
      })
    }
  })

  changePassword = ({ resetToken, password }) => ({
    type: CHANGE_PASSWORD,
    promise: (dispatch, getState) => {
    const { config } = getState()
    const themeId = _.get(config, 'websiteConfig.vigilanceHubThemeId')
    return sdk
      .auth
      .changePassword({ resetToken, password, themeId })
    }
  })

  logout = ({ skipApi } = {}) => ({
    type: LOGOUT,
    promise: (dispatch, getState) => {
    return sdk
      .auth
      .logout({ skipApi })
      .then(() => {
        dispatch(reportsActions.resetStore())
        dispatch(followUpActions.resetStore())
      })
    }
  })

  updateUser = ({ 
    id = '@me',
    title,
    firstName,
    lastName,
    email,
    occupation,
    professionId,
    houseNumberOrName,
    address,
    company,
    addressLineTwo,
    city,
    county,
    postalCode,
    telephoneCountryCode,
    telephone,
    telephoneExtension,
    smsCommunicationsPermitted,
    emailCommunicationsPermitted,
    organisationIds,
    details
  }) => ({
    type: UPDATE_AUTH_USER,
    promise: (dispatch, getState) => {
      const smsPermitted = _.isNil(smsCommunicationsPermitted) ? smsCommunicationsPermitted : _.get(smsCommunicationsPermitted, '0') === 'on'
      const emailPermitted = _.isNil(emailCommunicationsPermitted) ? emailCommunicationsPermitted : _.get(emailCommunicationsPermitted, '0') === 'on'
      return sdk
        .users
        .updateUser({ 
          id,
          title,
          firstName,
          lastName,
          email,
          occupation,
          professionId,
          houseNumberOrName,
          address,
          company,
          addressLineTwo,
          city,
          county,
          postalCode,
          telephoneCountryCode,
          telephone,
          telephoneExtension,
          smsCommunicationsPermitted: smsPermitted,
          emailCommunicationsPermitted: emailPermitted,
          organisationIds,
          details
        })
    }
  })
  
  editWatchListAdd = (param) => ({
    type: EDIT_WATCHLIST_ADD,
    promise: (dispatch, getState) => {
      if (_.isArray(param)) {
        let body = {}
        param.map(drug => {
          body = {
            ...body,
            [drug] : true
          }
        })
        return sdk.users.editWatchList(body)
      } else {
        return sdk.users.editWatchList({[param]: true})
      }
    },
  })

  editWatchListRemove = (param) => ({
    type: EDIT_WATCHLIST_REMOVE,
    promise: (dispatch, getState) => {
      return sdk.users.editWatchList({[param]: false})
    },
  })

  parseError = parseError

  keepMeLoggedIn = (payload = false) => ({
    type: SET_KEEP_ME_LOGGED_IN,
    payload: payload
  })
}

export default new AuthActions()
