import _ from 'lodash'
import { createSelector } from 'reselect'

import { PENDING, SUCCESS } from '../../reduxPromise'

export const getIsLoading = state => _.get(state, 'reports.status') === PENDING
export const getResults = state => _.get(state, 'reports.results') || []
export const getHasMore = state => _.get(state, 'reports.total') != null &&
_.get(state, 'reports.results.length') < _.get(state, 'reports.total')

export const getReportsHasBeenSearched = state => {
  return (
    state.reports.query ||
    !_.isEmpty(state.reports.storeIds) ||
    state.reports.status === SUCCESS
  )
}
export const getError = state => _.get(state, 'reports.error')
export const getIsInitial = state => !_.get(state, 'reports.status')
export const getQuery = state => _.get(state, 'reports.filters.query')
export const getSize = state => _.get(state, 'reports.size')
export const getPage = state => _.get(state, 'reports.page')
export const getStoreIds = state => _.get(state, 'reports.storeIds')
export const getStatus = state => _.get(state, 'reports.status')
export const getCases = state => _.get(state, 'report.cases')
export const getCasesSummary = state => _.get(state, 'reports.cases.summary')

export const getFilters = state => _.get(state.reports, 'filters', {})
export const getNumberOfActiveFilters = createSelector(
  getFilters,
  (filters) => {
    return _.reduce(filters, (total, value) => {
      return value ? total + 1 : total
    }, 0)
  }
)

export const getCaseFilters = createSelector(
  getCasesSummary,
  (cases = []) => {
    return cases
  }
)

export const getExtReportIdForQuery = createSelector(
  getFilters,
  (filters) => {
    const extReportId = _.get(filters, 'extReportId')
    return (extReportId !== 'all') ? extReportId : ''
  }
)
